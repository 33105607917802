import React from "react";
import { useTranslation } from "react-i18next";

export default function Legal() {
    const [t] = useTranslation( 'global' );

    return (
        <React.Fragment>
            <svg className="svg-circle">
                <circle cx="0%" cy="64px" r="280" fill="#EDF9F2" />
                <circle cx="0%" cy="64px" r="140" fill="#FFF" />
            </svg>
            <div className="v-text-container v-coopcentral">
                <h1>{t('legal.title')}</h1>
                <p>{t('legal.description')}</p>
                <ul>
                    <li>
                        <a href="https://publicmonettadocuments.s3.amazonaws.com/AvisoPrivacidad.pdf" target="_blank" rel="noreferrer" >Aviso de privacidad Monetta</a>
                    </li>
                    <li>
                        <a href="https://publicmonettadocuments.s3.amazonaws.com/DepositoDeBajoMonto-1.pdf" target="_blank" rel="noreferrer" >Reglamento de depósito de bajo monto</a>
                    </li>
                    <li>
                        <a href="https://publicmonettadocuments.s3.amazonaws.com/PoliticaTramientoDatos.pdf" target="_blank" rel="noreferrer" >Política de tratamiento de información y datos personales Monetta Colombia S.A.S</a>
                    </li>
                    <li>
                        <a href="https://publicmonettadocuments.s3.amazonaws.com/TerminosYCondicionesBilleteraMonetta.pdf" target="_blank" rel="noreferrer" >Términos y condiciones de uso de billetera Monetta</a>
                    </li>
                    <li>
                        <a href="https://publicmonettadocuments.s3.amazonaws.com/TerminosYCondicionesEcosistemaMonetta.pdf" target="_blank" rel="noreferrer" >Términos y condiciones de uso de ecosistema Monetta</a>
                    </li>
                    <li>
                        <a href="https://publicmonettadocuments.s3.amazonaws.com/TiendaServicioACI.pdf" target="_blank" rel="noreferrer" >Tienda Monetta y servicio de asistente de compras internacionales</a>
                    </li>
                    <li>
                        <a href="https://publicmonettadocuments.s3.amazonaws.com/PreguntasFrecuentes.pdf" target="_blank" rel="noreferrer" >Preguntas frecuentes</a>
                    </li>
                </ul>
                <p>{t('legal.campaigns')}</p>
                <ul>
                    <li>
                        <a href="https://publicmonettadocuments.s3.amazonaws.com/terminosPioneros.pdf" target="_blank" rel="noreferrer" >800 pioneros con 10% de descuento</a>
                    </li>
                </ul>
            </div>
        </React.Fragment> 
    );
}