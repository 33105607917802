import i01 from './pf_01.webp';
import i02 from './pf_02.webp';
import i03 from './pf_03.webp';
import i04 from './pf_04.webp';
import i05 from './pf_05.webp';
import i06 from './pf_06.webp';
import i07 from './pf_07.webp';
import i08 from './pf_08.webp';
import i09 from './pf_09.webp';
import i10 from './pf_10.webp';
import i11 from './pf_11.webp';
import i12 from './pf_12.webp';
import i13 from './pf_13.webp';
import i14 from './pf_14.webp';
import i15 from './pf_15.webp';
import i16 from './pf_16.webp';
import i17 from './pf_17.webp';
import i18 from './pf_18.webp';
import i19 from './pf_19.webp';
import i20 from './pf_20.webp';
import i21 from './pf_21.webp';
import i22 from './pf_22.webp';
import i23 from './pf_23.webp';
import i28 from './pf_28.webp';

export let logosMonettaPF = [i28, i01, i02, i03, i04, i05, i06, i07, i08, i09, i10, i11, i12, i13, i14, i15, i16, i17, i18, i19, i20, i21, i22, i23]