import pse from './img-pse.webp';
import i472 from './img-472.webp';
import sp from './img-sp.webp';
import cs from './img-cs.webp';
import sc from './img-sc.webp';
import mr from './img-mr.webp';
import jer from './img-jer.webp';
import pr from './img-pr.webp';
import pp from './img-pp.webp';
import coop from './img-coop.webp';
import fc from './img-fc.webp';

import ef from './img-ef.webp';
import kus from './img-kus.webp';

import ea from './img-ea.webp';
import bccc from './img-bccc.webp';
import visa from './img-visa.webp';
import ca from './img-ca.webp';
import aws from './img-aws.webp';
import mati from './img-mati.webp';
import eth from './img-eth.webp';

// import amazon from './img_amazon.webp'
import polo from './img_polo.webp'
import nike from './img_nike.webp'
import sephora from './img_sephora.webp'

export let logosBank = [pse, i472, sp, cs, sc, mr, jer, pr, pp, coop, fc]

export let logosMonetta = [ef, kus, pse]

export let partners = [bccc, visa, ca, aws, mati, ea, ef, eth, kus]

export let newMonettas = [ef, i472, sp, cs, sc, mr, jer, pr, pp, coop, fc ]

export let newCop = [bccc]

export let homeMonetta = [eth]
export let homeCard = [bccc, visa]
export let homeShopper = [nike, polo, sephora]