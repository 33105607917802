import React from "react";
import { useTranslation } from "react-i18next";

import StoreButtons from '../components/StoreButtons';

export default function Pioneers() {
    const [t] = useTranslation( 'global' );
    
    return (
        <React.Fragment>
            <div className="v-center-container v-pioneers-landing">
                <div className="v-text-container">
                    <div>
                        <h1>{t('pioneers.head-title')}</h1>
                        <h4>{t('pioneers.title')}</h4>
                        <p>{t('pioneers.description')}</p>
                    </div>
                </div>
            </div>
            {/* <div className="v-pioneers v-pioneers-index">
                <div className="v-text-container">
                    <div></div>
                    <div>
                        <h1>{t('pioneers.head-title')}</h1>
                        <h4>{t('pioneers.title')}</h4>
                        <p>{t('pioneers.description')}</p>
                    </div>
                </div>
            </div> */}
            <div className="v-pioneers v-pioneers-steps">
                <svg className="svg-circle v-pioneers-index-svg">
                    <circle cx="0" cy="0" r="280" fill="#F4F7EB" />
                    <circle cx="0" cy="0" r="140" fill="#FFF" />
                </svg>
                <h2>{t('pioneers.subtitle')}</h2>
                <div className="v-text-container">
                    <div></div>
                    <div>
                        <h4>{t('pioneers.step-1')}</h4>
                        <p>{t('pioneers.step-1-description')}</p>
                        <StoreButtons />
                    </div>
                </div>
                <div className="v-text-container">
                    <div></div>
                    <div>
                        <h4>{t('pioneers.step-2')}</h4>
                        <p>{t('pioneers.step-2-description')}</p>
                        <ul>
                            <li>{t('pioneers.step-2-li1')}</li>
                            <li>{t('pioneers.step-2-li2')}</li>
                        </ul>
                    </div>
                </div>
                <div className="v-text-container">
                    <div></div>
                    <div>
                        <h4>{t('pioneers.step-3')}</h4>
                        <p>{t('pioneers.step-3-description')}</p>
                        <ul>
                            <li>{t('pioneers.step-3-li1')}</li>
                            <li>{t('pioneers.step-3-li2')}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="v-pioneers v-pioneers-warn">
                <div className="v-text-container">
                    <h4>{t('pioneers.cta-banner-small')}</h4>
                </div>
            </div>
            <div className="v-pioneers v-pioneers-cta">
                <div className="v-text-container">
                    <div></div>
                    <div>
                        <h4>{t('pioneers.cta-banner')}</h4>
                        <p>{t('pioneers.cta-description')}</p>
                        <ul>
                            <li>{t('pioneers.cta-list.0.item')}</li>
                            <li>{t('pioneers.cta-list.1.item')}</li>
                            <li>{t('pioneers.cta-list.2.item')}</li>
                            <li>{t('pioneers.cta-list.3.item')}</li>
                            <li>{t('pioneers.cta-list.4.item')}</li>
                            <li>
                                <a href="https://publicmonettadocuments.s3.amazonaws.com/terminosPioneros.pdf" target="_blank" rel="noreferrer" > {t('pioneers.cta-list.5.item')} </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}