import React from "react";
import { useTranslation } from "react-i18next";

import ListCoopcentral from "../components/ListCoopcentral";

export default function Coopcentral() {
    const [t] = useTranslation( 'global' );

    return (
        <React.Fragment>
            <svg className="svg-circle">
                <circle cx="0%" cy="64px" r="280" fill="#EDF9F2" />
                <circle cx="0%" cy="64px" r="140" fill="#FFF" />
            </svg>
            <div className="v-text-container v-coopcentral">
                <h1>{t('coopcentral.title')}</h1>
                <p>{t('coopcentral.description')}</p>
                <ul>
                    <ListCoopcentral />
                </ul>
            </div>
        </React.Fragment> 
    );
}