import React from "react";
import { useTranslation } from "react-i18next";

import TableFees from "../components/TableFees"

export default function Fees() {
    const [t] = useTranslation( 'global' );

    return (
        <React.Fragment>
            <svg className="svg-circle">
                <circle cx="0%" cy="64px" r="280" fill="#EDF9F2" />
                <circle cx="0%" cy="64px" r="140" fill="#FFF" />
            </svg>
            <div className="v-text-container v-fees">
                <h1>{t('fees.content.title')}</h1>
                <p>{t('fees.content.parragraph-a')}</p>
                <p>{t('fees.content.parragraph-b')}</p>
            </div>
            <TableFees />
        </React.Fragment> 
    );
}