import React from "react";
import { useTranslation } from "react-i18next";

import global from "../locales/es/global.json"

export default function Fees() {
    const [t] = useTranslation( 'global' );

    const indexWallet = global.fees.wallet.length
    const indexBank = global.fees.bank.length
    const indexEcommerce = global.fees.ecommerce.length

    const WalletTable = () => {
        const element = []
        for (let index = 0; index < indexWallet; index++) {
            if (global.fees.wallet[index]["sub-description"]) {
                element.push(
                    <div className="table-row" key={index}>
                        <div className="table-col">
                            {t(`fees.wallet.${index}.description`)}
                            <span className="table-subdescription">
                                {t(`fees.wallet.${index}.sub-description`)}
                            </span>
                        </div>
                        <div className="table-col">
                            <span className="table-responsive">
                                {t(`fees.wallet.0.fee`)}
                            </span>
                            {t(`fees.wallet.${index}.fee`)}
                        </div>
                        <div className="table-col">
                            <span className="table-responsive">
                                {t(`fees.wallet.0.currency`)}
                            </span>
                            {t(`fees.wallet.${index}.currency`)}
                        </div>
                    </div>
                )
            } else {
                element.push(
                    <div className="table-row" key={index}>
                        <div className="table-col">
                            {t(`fees.wallet.${index}.description`)}
                        </div>
                        <div className="table-col">
                            <span className="table-responsive">
                                {t(`fees.wallet.0.fee`)}
                            </span>
                            {t(`fees.wallet.${index}.fee`)}
                        </div>
                        <div className="table-col">
                            <span className="table-responsive">
                                {t(`fees.wallet.0.currency`)}
                            </span>
                            {t(`fees.wallet.${index}.currency`)}
                        </div>
                    </div>
                )
            }
        }
        return element
    }

    const BankTable = () => {
        const element = []
        for (let index = 0; index < indexBank; index++) {
            if (global.fees.bank[index]["sub-description"]) {
                element.push(
                    <div className="table-row" key={index}>
                        <div className="table-col">
                            {t(`fees.bank.${index}.description`)}
                            <span className="table-subdescription">
                                {t(`fees.bank.${index}.sub-description`)}
                            </span>
                        </div>
                        <div className="table-col">
                            <span className="table-responsive">
                                {t(`fees.bank.0.fee`)}
                            </span>
                            {t(`fees.bank.${index}.fee`)}
                        </div>
                        <div className="table-col">
                            <span className="table-responsive">
                                {t(`fees.bank.0.currency`)}
                            </span>
                            {t(`fees.bank.${index}.currency`)}
                        </div>
                    </div>
                )
            } else {
                element.push(
                    <div className="table-row" key={index}>
                        <div className="table-col">
                            {t(`fees.bank.${index}.description`)}
                        </div>
                        <div className="table-col">
                            <span className="table-responsive">
                                {t(`fees.bank.0.fee`)}
                            </span>
                            {t(`fees.bank.${index}.fee`)}
                        </div>
                        <div className="table-col">
                            <span className="table-responsive">
                                {t(`fees.bank.0.currency`)}
                            </span>
                            {t(`fees.bank.${index}.currency`)}
                        </div>
                    </div>
                )
            }
        }
        return element
    }

    const EcommerceTable = () => {
        const element = []
        for (let index = 0; index < indexEcommerce; index++) {
            if (global.fees.ecommerce[index]["sub-description"]) {
                element.push(
                    <div className="table-row" key={index}>
                        <div className="table-col">
                            {t(`fees.ecommerce.${index}.description`)}
                            <span className="table-subdescription">
                                {t(`fees.ecommerce.${index}.sub-description`)}
                            </span>
                        </div>
                        <div className="table-col">
                            <span className="table-responsive">
                                {t(`fees.ecommerce.0.fee`)}
                            </span>
                            {t(`fees.ecommerce.${index}.fee`)}
                        </div>
                        <div className="table-col">
                            <span className="table-responsive">
                                {t(`fees.ecommerce.0.currency`)}
                            </span>
                            {t(`fees.ecommerce.${index}.currency`)}
                        </div>
                    </div>
                )
            } else {
                element.push(
                    <div className="table-row" key={index}>
                        <div className="table-col">
                            {t(`fees.ecommerce.${index}.description`)}
                        </div>
                        <div className="table-col">
                            <span className="table-responsive">
                                {t(`fees.ecommerce.0.fee`)}
                            </span>
                            {t(`fees.ecommerce.${index}.fee`)}
                        </div>
                        <div className="table-col">
                            <span className="table-responsive">
                                {t(`fees.ecommerce.0.currency`)}
                            </span>
                            {t(`fees.ecommerce.${index}.currency`)}
                        </div>
                    </div>
                )
            }
        }
        return element
    }

    return (
        <React.Fragment>
            <div className="v-text-container">
                <div className="v-table fees-table">
                    {/* TODO do with MAP or iteration
                        Hrs spent = 5.25
                    */}
                    <WalletTable />
                </div>
                <div className="v-table fees-table">
                    {/* Bank table */}
                    <BankTable />
                </div>
                <div className="v-table fees-table">
                    {/* Bank table */}
                    <EcommerceTable />
                </div>
            </div>
        </React.Fragment> 
    );
}