import React from "react";
import { useTranslation } from "react-i18next";

import logo from "../images/monetta-logo.svg"
import siATodo from "../images/siATodoConMonetta.svg"
import VerticalsLP from "../components/VerticalsLP";
import StoreButtons from "../components/StoreButtons";

export default function Landing() {
    const [t] = useTranslation( 'global' );
    
    return (
        <React.Fragment>
            <header>
                <img src={logo} className="monetta-logo" alt={t('monetta.monetta')} width="158" height="32" />
            </header>
            <div className="v-partial-height v-full-width c-lp">
                <div className="v-xyz">
                    <h1>Con Monetta tienes todo en un solo lugar</h1>
                    <p>Olvídate de cambiar de app o billetera. Haz tus transacciones en Colombia y USA de manera facil y segura.</p>
                </div>
            </div>
            <div className="v-slider-actions">
                <li>
                    <a  className="force-button transparent" href="#usd">Ahorro en dólares 🤑</a>
                </li>
                <li>
                    <a  className="force-button transparent" href="#cop">Tarjeta débito 💳</a>
                </li>
                <li>
                    <a  className="force-button transparent" href="#ecommerce">Tienda el línea 🛍️</a>
                </li>
            </div>
            <VerticalsLP />
            <div className="v-home-cta">
                <div className="v-text-container">
                    <div>
                        <img width="auto" height="146" src={siATodo} alt='Con Monetta si a todo' />
                    </div>
                    <div>
                        <h4>{t('home.cta.title')}</h4>
                        <p>{t('home.cta.description')}</p>
                        <StoreButtons />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}