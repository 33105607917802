import React from "react";
import { useTranslation } from "react-i18next";
import { EffectFade, Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";

// import Slide from '../components/Slide';
// import LeadForm from "../components/LeadForm";
import NothingElse from "../components/Slides/NothingElse";
import Discount10 from "../components/Slides/Discount10";
import StoreButtons from "../components/StoreButtons";
import Verticals from "../components/Verticals";
import Coworker from "../components/Coworker";
import Logo from "../components/Logo";

// import {logosBank, logosMonetta, partners} from '../images/img-logos/logos';
import {partners} from '../images/img-logos/logos';
import siATodo from "../images/siATodoConMonetta.svg"
import justSayYes from "../images/justSayYesMonetta.svg"

import i18next from 'i18next';

export default function Home() {
    const [t] = useTranslation( 'global' );

    var lng = i18next.language
    // var lng = i18next.language !== /es|en/i ? "en" : i18next.language
    let slogan = lng === "es" ? siATodo : justSayYes
    
    return (
        <React.Fragment>
            <Swiper
                loop={true}
                effect={"fade"}
                pagination={true}
                modules={[EffectFade, Pagination, Autoplay]}
                autoplay={{
                    delay: 20000,
                    disableOnInteraction: false
                }} >
                <SwiperSlide>
                    <Discount10 />
                </SwiperSlide>
                <SwiperSlide>
                    <NothingElse />
                </SwiperSlide>
            </Swiper>
            
            <Verticals />
            
            <div className="v-home-ecommerce">
                <div className="v-text-container">
                    <div className={`${lng}-img`}></div>
                    <div>
                        <h4>{t('home.slider-store.title')}</h4>
                        <p>{t('home.slider-store.description1')}</p>
                        <p>{t('home.slider-store.description2')}</p>
                    </div>
                </div>
            </div>

            {/* 
            <div className="v-home-logos">
                <div className="v-text-container">
                    <h4>{t('home.logos.title-1')} <span>{t('home.logos.title-2')}</span></h4>
                    <p>{t('home.logos.description-debit')}</p>
                    <ul className="v-flex-logos">
                        <Logo arr={logosBank} len={logosBank.length}/>
                    </ul>
                    <p>{t('home.logos.description-monetta')}</p>
                    <ul className="v-flex-logos">
                        <Logo arr={logosMonetta} len={logosMonetta.length}/>
                    </ul>
                </div>
            </div> */}

            <div className="v-home-team">
                <svg className="svg-circle">
                    <circle cx="0%" cy="0px" r="280" fill="#EDF9F2" />
                    <circle cx="0%" cy="0px" r="140" fill="#FFF" />
                </svg>
                <div className="v-text-container">
                    <h4>{t('home.team-title')}</h4>
                    <Coworker/>
                </div>
            </div>

            <div className="v-home-partners">
                <svg className="svg-circle">
                    <circle cx="280" cy="0px" r="280" fill="#F5F4F9" />
                    <circle cx="280" cy="0px" r="140" fill="#FFF" />
                </svg>
                <div className="v-text-container">
                    <h4>{t('home.logos.partners')}</h4>
                    <ul className="v-flex-logos">
                        <Logo arr={partners} len={partners.length}/>
                    </ul>
                </div>
            </div>

            <div className="v-home-store">
                <div className="v-text-container">
                    <div>
                        <h4>{t('home.ecommerce.title')}</h4>
                        <p>{t('home.ecommerce.description')}</p>
                        <h6>{t('home.ecommerce.title-eth')}</h6>
                        <p>{t('home.ecommerce.description-eth')}</p>
                        <a className="force-button green" href="https://publicmonettadocuments.s3.amazonaws.com/white-paper.pdf" target="_blank" rel="noreferrer" >{t('home.ecommerce.cta')}</a>
                    </div>
                </div>
            </div>

            <div className="v-home-cta">
                <div className="v-text-container">
                    <div>
                        <img width="auto" height="146" src={slogan} alt='Con Monetta si a todo' />
                    </div>
                    <div>
                        <h4>{t('home.cta.title')}</h4>
                        <p>{t('home.cta.description')}</p>
                        {/* <LeadForm email={t('home.slider-init.email')} cta={t('home.slider-init.cta')}/>
                        <p>{t('home.cta.button-description')}</p> */}
                        <StoreButtons />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}