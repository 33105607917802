import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import rt from "../locales/routes.json"
import logo from "../images/monetta-logo.svg"

import LangSwitcher from "./LangSwitcher";

export default function Header() {
    const [t] = useTranslation( 'global' );
    const [isNavExpanded, setIsNavExpanded] = useState(false);
    
    return (
        <header>
            <img src={logo} className="monetta-logo" alt={t('monetta.monetta')} width="158" height="32" />
            <button
                aria-label="Mobile Menu"
                className="hamburger"
                onClick={() => {
                    setIsNavExpanded(!isNavExpanded);
                }}
            >
                <svg viewBox='0 0 10 10' width='20' height='20'>
                    <path d='M1 1h8M1 4h 8M1 7h8' 
                            stroke='#FFF' 
                            strokeWidth='1' 
                            strokeLinecap='round'/>
                </svg>
                {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="white"
                >
                <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                    clipRule="evenodd"
                />
                </svg> */}
            </button>
            <ul className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}>
                <li>
                    <NavLink to={rt.home} onClick={() => { setIsNavExpanded(false) }} >{t('header.home')}</NavLink>
                </li>
                <li>
                    <NavLink to={rt.pioneers} onClick={() => { setIsNavExpanded(false) }} >{t('header.pioneers')}</NavLink>
                </li>
                <li>
                    <NavLink to={rt.payments} onClick={() => { setIsNavExpanded(false) }} >{t('header.payments')}</NavLink>
                </li>
                <li>
                   <LangSwitcher /> 
                </li> 
            </ul>
        </header>
    );
}