import React from "react";
import { useTranslation } from "react-i18next";

import global from "../locales/es/global.json"

export default function ListCoopcentral() {
    const [t] = useTranslation( 'global' );

    const len = global.coopcentral.list.length
    const places = []
    
    for (let index = 0; index < len; index++) {
        places.push(
            <li key={index}>
                <h6>{t(`coopcentral.list.${index}.city`)}</h6>
                <p>{t(`coopcentral.list.${index}.location`)}</p>
            </li>
        )
    }

    return places
}