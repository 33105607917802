import React from "react";
import { Outlet, BrowserRouter, Routes, Route } from "react-router-dom";

import rt from "./locales/routes.json"

import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import { RedirectToStore } from "./components/RedirectToStore";

import Home from './pages/Home';
import Privacy from "./pages/Privacy";
import Pioneers from "./pages/Pioneers";
import Payments from "./pages/Payments";
import Fees from "./pages/Fees";
import Legal from "./pages/Legal";
import MobileFees from "./pages/MobileFees"
import Coopcentral from "./pages/Coopcentral";
import MobileCoopcentral from "./pages/MobileCoopcentral";
import MobileForm from "./pages/MobileForm";
import MobileSubmitted from "./pages/MobileSubmitted";
import Landing from "./pages/Landing";

import './App.css';
import './global.sass';

export default function App() {

  const WebsiteLayout = () => (
    <>
      <ScrollToTop />
      <Header />
      <Outlet />
      <Footer />
    </>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path={rt.mobilefees} element={<MobileFees/>} />
        <Route exact path={rt.mobilebank} element={<MobileCoopcentral/>} />
        <Route exact path={rt.mobileform} element={<MobileForm/>} />
        <Route exact path={rt.mobilesubmitted} element={<MobileSubmitted/>} />
        <Route exact path={rt.mobilestoreredirect} element={<RedirectToStore/>} />
        <Route exact path="lp/lanzamiento" element={<Landing/>} />
        <Route element={<WebsiteLayout />} >
          <Route path="*" element={<Home/>} />
          <Route exact path={rt.home} element={<Home/>} />
          <Route exact path="privacidad" element={<Privacy/>} />
          <Route exact path={rt.pioneers} element={<Pioneers/>} />
          <Route exact path={rt.payments} element={<Payments/>} />
          <Route exact path={rt.legal} element={<Legal/>} />
          <Route exact path={rt.fees} element={<Fees/>} />
          <Route exact path="tarifas" element={<Fees/>} />
          <Route exact path={rt.bank} element={<Coopcentral/>} />
          <Route exact path="banco" element={<Coopcentral/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

