import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import rt from "../locales/routes.json"

export default function Footer() {
    const [t] = useTranslation( 'global' );

    return (
        <footer>
            <div className="v-text-container">
                <div className="menu">
                    <ul>
                        <li>
                            <p>Monetta</p>
                            <ul className="sublist">
                                <li>
                                    <NavLink to={rt.home}>{t('header.home')}</NavLink>
                                </li>
                                <li>
                                    <NavLink to={rt.pioneers}>{t('header.pioneers')}</NavLink>
                                </li>
                                <li>
                                    <NavLink to={rt.payments}>{t('header.payments')}</NavLink>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p>Legal</p>
                            <ul className="sublist">
                                <li>
                                    <NavLink to={rt.legal}>{t('legal.title')}</NavLink>
                                </li>
                                <li>
                                    <NavLink to={rt.bank}>{t('coopcentral.title')}</NavLink>
                                </li>
                                <li>
                                    <NavLink to={rt.fees}>{t('fees.content.title')}</NavLink>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p>Follow us</p>
                            <ul className="sublist net">
                                <li>
                                    <a href="https://www.instagram.com/monettapp/" target="_blank" rel="noreferrer" >
                                        <svg width="36px" height="36px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="2" y="2" width="20" height="20" rx="5" stroke="#383380" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <circle cx="12" cy="12" r="4" stroke="#383380" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M18 6L18 6.01" stroke="#383380" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </a>
                                </li> 
                                {/* <li>
                                    <a href="https://apple.com" target="_blank" rel="noreferrer" >
                                        <svg width="36px" height="36px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M22.54 6.42a2.765 2.765 0 0 0-1.945-1.957C18.88 4 12 4 12 4s-6.88 0-8.595.463A2.765 2.765 0 0 0 1.46 6.42C1 8.148 1 11.75 1 11.75s0 3.602.46 5.33a2.765 2.765 0 0 0 1.945 1.958C5.121 19.5 12 19.5 12 19.5s6.88 0 8.595-.462a2.765 2.765 0 0 0 1.945-1.958c.46-1.726.46-5.33.46-5.33s0-3.602-.46-5.33ZM9.75 8.479v6.542l5.75-3.271-5.75-3.271Z" fill="#383380"/></svg>
                                    </a>
                                </li>  */}
                                <li>
                                    <a href="https://www.linkedin.com/company/monettaapp" target="_blank" rel="noreferrer" >
                                        <svg fill="#383380" width="36px" height="36px" viewBox="-1.5 0 19 19" xmlns="http://www.w3.org/2000/svg" class="cf-icon-svg"><path d="M15.917 17.5H.083V1.666h15.834zM5.77 6.427a.96.96 0 0 0-1.032-.96.962.962 0 1 0-.025 1.92h.013a.963.963 0 0 0 1.044-.96zm-.123 1.719H3.803v5.552h1.845zm6.658 2.368c0-1.705-.91-2.5-2.125-2.5a1.832 1.832 0 0 0-1.663.917v-.786H6.67c.025.52 0 5.554 0 5.554h1.848v-3.102a1.264 1.264 0 0 1 .06-.45 1.011 1.011 0 0 1 .946-.676c.67 0 .936.51.936 1.256V13.7h1.846zm-3.8-1.564.013-.019v.019z"/></svg>
                                    </a>
                                </li> 
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="legal">
                    <p>Monetta ® 2023</p>
                </div>
            </div>
        </footer>
    )
}