import React from "react";
import { useTranslation } from "react-i18next";

export default function LangSwitcher() {
    const [t, i18n] = useTranslation( 'global' );
    
    if(i18n.language === 'es') {
        return (
            <button aria-label="English Language" onClick={() => i18n.changeLanguage('en')}>{t('header.languages.en.code')}</button>
        );
    } else {
        return (
            <button aria-label="Lenguaje Español" onClick={() => i18n.changeLanguage('es')}>{t('header.languages.es.code')}</button>
        );
    }
}