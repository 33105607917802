import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import rt from "../locales/routes.json"
import Logo from "../components/Logo";
import {newCop} from '../images/img-logos/logos';
import {logosMonettaPF} from '../images/logos-monetta-pf/logos';

import i18next from 'i18next';

export default function Payments() {
    const [t] = useTranslation( 'global' );

    var lng = i18next.language
    // lng = i18next.language !== "es" || "en" ? "en" : i18next.language

    return (
        <React.Fragment>
            {/* <svg className="svg-circle v-payments-index-svg">
                <circle cx="280" cy="0px" r="280" fill="#F3F2F8" />
                <circle cx="280" cy="0px" r="140" fill="#FFF" />
            </svg> */}
            <div className="v-payments v-payments-index">
                <div className="v-text-container">
                    <div>
                        <h1>{t('payments.intro.title')}</h1>
                        <p>{t('payments.intro.description')}</p>
                        <p>{t('payments.fees.text')} <Link to={rt.fees} preventScrollReset={false} >{t('payments.fees.link')}</Link></p>
                    </div>
                    <div className="v-payment-image"></div>
                </div>
            </div>
            <div className="v-payments v-payments-card v-ramp-risotto">
                <svg className="svg-circle v-payments-index-svg">
                    <circle cx="0" cy="0" r="280" fill="#F4F7EB" />
                    <circle cx="0" cy="0" r="140" fill="#FFF" />
                </svg>
                <div className="v-text-container">
                    <div className={`${lng}-img v-payment-image`}></div>
                    <div>
                        <h4>{t('payments.card.title')}</h4>
                        <p>{t('payments.card.description')}</p>
                        <ul className="v-flex-logos">
                            <Logo arr={newCop} len={newCop.length}/>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="v-payments v-payments-monetta">
                <svg className="svg-circle v-payments-index-svg">
                    <circle cx="280" cy="0px" r="280" fill="#6BE8A5" />
                    <circle cx="280" cy="0px" r="140" fill="#3DCC7F" />
                </svg>
                <div className="v-text-container">
                    <div>
                        <h4>{t('payments.monettas.title')}</h4>
                        <p>{t('payments.monettas.description')}</p>
                        <ul className="v-flex-logos">
                            <Logo arr={logosMonettaPF} len={logosMonettaPF.length}/>
                        </ul>
                    </div>
                    <div className={`${lng}-img v-payment-image`}></div>
                </div>
            </div>
            <div className="v-payments v-payments-exchange">
                <div className="v-text-container">
                    <div className="v-payment-image"></div>
                    <div>
                        <h4>{t('payments.convert.title')}</h4>
                        <p>{t('payments.convert.description')}</p>
                    </div>
                </div>
            </div>
            <div className="v-pioneers v-pioneers-warn">
                <div className="v-text-container">
                    <h4>{t('payments.banner')}</h4>
                    <h1>{t('monetta.copy')}</h1>
                </div>
            </div>
        </React.Fragment> 
    );
}