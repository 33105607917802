import React from "react";
import { useTranslation } from "react-i18next";

import monettaWallet from "../images/img-monetta@2x.webp"
import debitCard from "../images/img-tarjeta@2x.webp"
import shopperAssistant from "../images/img-tienda@2x.webp"

import Logo from "./Logo";
import {homeCard, homeMonetta, homeShopper} from '../images/img-logos/logos';

export default function Verticals() {
    const [t] = useTranslation( 'global' );
    
    return (
        <div className="v-ramp-risotto">
            <div className="c-verticals" id="usd">
                <hgroup>
                    <h4 className="current">{t('home.verticals.monettas.title')}</h4>
                </hgroup>
                <div className="v-slider-description">
                    <p>{t('home.verticals.monettas.description1')}</p>
                    <p>{t('home.verticals.monettas.description2')}</p>
                    <h6>{t('home.verticals.monettas.subtitle')}</h6>
                    <ul>
                        <li>{t('home.verticals.monettas.list-1')}</li>
                        <li>{t('home.verticals.monettas.list-2')}</li>
                        <li>{t('home.verticals.monettas.list-3')}</li>
                    </ul>
                    <ul className="v-flex-logos">
                        <Logo arr={homeMonetta} len={homeMonetta.length}/>
                    </ul>
                </div>
                <img src={monettaWallet} width="480" height="auto" alt="Monetta App"/>
            </div>
            <div className="c-verticals" id="cop">
                <hgroup>
                    <h4 className="current">{t('home.verticals.debit.title')}</h4>
                </hgroup>
                <div className="v-slider-description">
                    <p>{t('home.verticals.debit.description1')}</p>
                    <h6>{t('home.verticals.debit.subtitle')}</h6>
                    <ul>
                        <li>{t('home.verticals.debit.list-1')}</li>
                        <li>{t('home.verticals.debit.list-2')}</li>
                        <li>{t('home.verticals.debit.list-3')}</li>
                    </ul>
                    <ul className="v-flex-logos">
                        <Logo arr={homeCard} len={homeCard.length}/>
                    </ul>
                </div>
                <img src={debitCard} width="480" height="auto" alt="Monetta App"/>
            </div>
            <div className="c-verticals" id="ecommerce">
                <hgroup>
                    <h4 className="current">{t('home.verticals.assistant.title')}</h4>
                </hgroup>
                <div className="v-slider-description">
                    <p>{t('home.verticals.assistant.description1')}</p>
                    <h6>{t('home.verticals.assistant.subtitle')}</h6>
                    <ul>
                        <li>{t('home.verticals.assistant.list-1')}</li>
                        <li>{t('home.verticals.assistant.list-2')}</li>
                        <li>{t('home.verticals.assistant.list-3')}</li>
                    </ul>
                    <ul className="v-flex-logos">
                        <Logo arr={homeShopper} len={homeShopper.length}/>
                    </ul>
                </div>
                <img src={shopperAssistant} width="480" height="auto" alt="Monetta App"/>
            </div>
        </div>
    )
}