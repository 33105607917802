export const RedirectToStore = () => {
  // detect mobile operating system
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;
  let os = "";

  if (/android/i.test(userAgent)) {
    os = "android";
  } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    os = "ios";
  }

  // redirect to appropriate app store
  if (os === "android") {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.monetta";
  } else if (os === "ios") {
    window.location.href =
      "https://apps.apple.com/co/app/monetta/id1567809169?l=es";
  } else {
    window.location.href = "https://www.monetta.app/"
  }

  return <></>;
};
