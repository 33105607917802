import React from "react";

export default function Logo({arr, len}) {
    const logos = []
    // console.log(arr)
    // console.log(len)

    for (let index = 0; index < len; index++) {
        logos.push(
            <li key={index}>
                <img src={arr[index]} alt="Aliado" width="auto" height="60" />
            </li>
        )
    }

    return logos
}
