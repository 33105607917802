import React from "react";
import ListCoopcentral from "../components/ListCoopcentral";

export default function MobileCoopcentral() {
    return (
        <React.Fragment>
            <svg className="svg-circle">
                <circle cx="0%" cy="64px" r="280" fill="#EDF9F2" />
                <circle cx="0%" cy="64px" r="140" fill="#FFF" />
            </svg>
            <div className="v-text-container v-coopcentral">
                <ListCoopcentral />
            </div>
        </React.Fragment> 
    );
}