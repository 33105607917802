import React from "react";
import logo from "../images/simon-success.webp"

export default function MobileSubmitted() {
    return(
        <React.Fragment>
            <svg className="svg-circle">
                <circle cx="0%" cy="64px" r="280" fill="#EDF9F2" />
                <circle cx="0%" cy="64px" r="140" fill="#FFF" />
            </svg>
            <div className="v-text-container">
                <h1>Listooooo!</h1>
                <img width="100%" height="auto" src={logo} className="monetta-logo" alt="Yeah!"/>
                <p>Información recibida, te hemos enviado un correo con el número de caso.</p>
            </div>
        </React.Fragment> 
    )
}