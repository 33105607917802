import React from "react";

import i18next from 'i18next';

import iOSBadge from '../images/badge-ios-es.webp'
import androidBadge from '../images/bagde-android-es.webp'
import iOSBadgeEN from '../images/badge-ios-en.webp'
import androidBadgeEN from '../images/bagde-android-en.webp'

export default function StoreButtons() {
    var lng = i18next.language

    // if (lng === "es") {
    //     return (
    //         <div className="c-storebuttons">
    //             <img src={androidBadge} alt="Playstore" width="auto" height={56} />
    //             <img src={iOSBadge} alt="AppStore" width="auto" height={56} />
    //         </div> 
    //     );
    // } else {
    //     return (
    //         <div className="c-storebuttons">
    //             <img src={androidBadgeEN} alt="Playstore" width="auto" height={56} />
    //             <img src={iOSBadgeEN} alt="AppStore" width="auto" height={56} />
    //         </div>
    //     );
    // }

    if (lng === "es") {
        return (
            <div className="c-storebuttons">
                <a href="https://play.google.com/store/apps/details?id=com.monetta" target="_blank" rel="noreferrer" >
                    <img src={androidBadge} alt="Playstore" width="auto" height={56} />
                </a>
                <a href="https://apps.apple.com/co/app/monetta/id1567809169?l=es" target="_blank" rel="noreferrer" >
                    <img src={iOSBadge} alt="AppStore" width="auto" height={56} />
                </a>
            </div>
        );
    } else {
        return (
            <div className="c-storebuttons">
                <a href="https://play.google.com/store/apps/details?id=com.monetta" target="_blank" rel="noreferrer" >
                    <img src={androidBadgeEN} alt="Playstore" width="auto" height={56} />
                </a>
                <a href="https://apps.apple.com/co/app/monetta/id1567809169?l=es" target="_blank" rel="noreferrer" >
                    <img src={iOSBadgeEN} alt="AppStore" width="auto" height={56} />
                </a>
            </div>
        );
    }
}