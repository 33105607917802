import React from "react";
import Salesforce from "../components/Salesforce";

export default function MobileForm() {
    return(
        <React.Fragment>
            <svg className="svg-circle">
                <circle cx="0%" cy="64px" r="280" fill="#EDF9F2" />
                <circle cx="0%" cy="64px" r="140" fill="#FFF" />
            </svg>
            <Salesforce />
        </React.Fragment> 
    )
}