import React from "react";
import TableFees from "../components/TableFees"

export default function MobileFees() {
    return (
        <React.Fragment>
            <svg className="svg-circle">
                <circle cx="0%" cy="64px" r="280" fill="#EDF9F2" />
                <circle cx="0%" cy="64px" r="140" fill="#FFF" />
            </svg>
            <TableFees />
        </React.Fragment> 
    );
}