import React from "react";

class Salesforce extends React.Component {

    render() {
      return (
        <div className="c-salesforce v-text-container">
            <p>Completa la información para enviar la solicitud.</p>
            <form 
                action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8" 
                method="post"
            >

                <input type="hidden" name="orgid" value="00D8X000000FpH6" />
                {/* <input type="hidden" name="retURL" value="https://monetta.app" /> */}
                <input type="hidden" name="retURL" value="https://www.monetta.app/mobile-submitted" />

                <label for="name">Nombre del contacto</label>
                <input 
                    id="name" maxlength="80" name="name" size="20" type="text" />
    
                <label for="email">Email</label>
                <input id="email" maxlength="80" name="email" size="20" type="email" />
                
                <label for="phone">Teléfono</label>
                <input id="phone" maxlength="40" name="phone" size="20" type="tel" />
                
                <label for="type">Tipo</label>
                <select id="type" name="type">
                    <option value=""> --Ninguno-- </option>
                    <option value="Consulta">Consulta</option>
                    <option value="Solicitud">Solicitud</option>
                    <option value="Queja">Queja</option>
                    <option value="Reclamo">Reclamo</option>
                    <option value="Felicitacion">Felicitación</option>
                    <option value="Sugerencia">Sugerencia</option>
                </select>
                
                <label for="description">Descripción</label>
                <textarea name="description"></textarea>
                
                <button aria-label="Enviar" type="submit">
                    Enviar
                </button>
            </form>
        </div>
      );
    }
  }

export default Salesforce;